// 充值 、 提现 / 转账 列表
export const getBalanceListApi = ({
  create_time,
  page,
  enter_id,
  status,
  type,
}) => {
  let url = '/account/balance/recharge/enter';
  let data = {
    create_time,
    page,
    enter_id,
    status,
  };
  let obj = {};
  let method = 'get';
  if (!type) {
    url = '/account/deposit/record';
    obj.data = data;
    method = 'post';
  } else {
    obj.params = data;
    data.type = type;
  }
  return {
    url,
    method,
    ...obj,
  };
};
// 充值审核 有id时为修改审核记录，无id时为提交充值审核
export const rechargeEnterValidateApi = ({
  enter_id,
  pay_time,
  price,
  pic,
  id = '',
  type,
  bills,
  chong_zhi_zhang_hu_value,
}) => ({
  url: `/account/balance/recharge/enter${id ? `/${id}` : ''}`,
  method: 'POST',
  data: {
    enter_id,
    pay_time: pay_time.format('YYYY-MM-DD'),
    price,
    pic,
    type,
    bills,
    chong_zhi_zhang_hu_value,
  },
});
// 提现申请
export const refundEnterValidateApi = ({ price, ...other }) => ({
  url: `/account/refund/add`,
  method: 'POST',
  data: {
    ...other,
    price: Number(price),
  },
});
// 充值审核详情
export const rechargeEnterDetailApi = (id) => ({
  url: `/account/balance/recharge/enter/${id}`,
});
// 撤销、删除 充值、提现 审核
export const revokeRechargeEnterApi = (id) => ({
  url: `/account/balance/recharge/enter/${id}`,
  method: 'delete',
});
// 删除充值审核
// export const deleteRechargeEnterApi = (id) => ({
//   url: `/account/balance/recharge/enter/${id}`,
//   method: 'delete',
// });
// // 撤销提现审核
// export const revokeWithdrawalEnterApi = (id) => ({
//   url: `/account/balance/recharge/enter/${id}`,
//   method: 'put',
// });
// // 删除提现审核
// export const deleteWithdrawalEnterApi = (id) => ({
//   url: `/account/balance/recharge/enter/${id}`,
//   method: 'delete',
// });
// 账户金额信息
export const accountMoneyInfoApi = (id) => ({
  url: `/account/info/${id}`,
});
// 账户余额信息
export const accountBalanceInfoApi = (id, value) => ({
  url: `/account/info/${id}`,
  params: {
    chong_zhi_zhang_hu_value: value,
  },
});
// 保证金充值及退款 operation_type 1. 交押金 2. 退押金
export const depositOperationApi = (operation_type, enter_id) => ({
  url: `/account/deposit/operation`,
  method: 'post',
  data: {
    operation_type,
    enter_id: Number(enter_id),
  },
});
// 上一次提现记录
export const lastWithdrawalInfoApi = (enter_id) => ({
  url: `/account/refund/default`,
  params: {
    enter_id,
  },
});
// 充值的关联账单信息
export const FinancilRechargeBillApi = (id) => ({
  url: `/account/balance/recharge/bill/${id}`,
});
// 余额的关联账单信息
export const FinancilRecordBillApi = (id) => ({
  url: `/account/balance/record/bill/${id}`,
});
// 余额明细
export const BalanceRecordApi = (params) => ({
  url: `/account/balance/record`,
  params,
});
// 已出账单
export const FinancialOutBillApi = (page, page_size) => ({
  url: `/financial/bill/outbill`,
  params: { page, page_size },
});
// 未出账单
export const FinancialNoBillApi = ({
  page,
  page_size,
  begin_time,
  end_time,
}) => ({
  url: `/financial/bill/notoutbill`,
  params: { page, page_size, begin_time, end_time },
});
// 已出账单 账单详情
export const FinancialOutBillDetailApi = (page, bill_id, page_size = 5) => ({
  url: `/financial/bill/getbilldetail`,
  params: { page, page_size, bill_id },
});
// 生成账单
export const RechargeCreateBillApi = (item_ids, bill_ids) => ({
  url: `/financial/bill/rechargecreatebill`,
  method: 'post',
  data: { item_ids, bill_ids },
});
// 获取账户待还额度
export const ConvoyLeaveMountApi = () => ({
  url: `/financial/bill/accountleaveamount`,
  method: 'post',
});
// 信用额度支付 order_id
export const CreditPayApi = (params) => ({
  url: '/convoy/cargocvge/order/credit/pay',
  method: 'post',
  data: params,
});
// 余额支付
export const BalancePayApi = (params) => ({
  url: '/convoy/cargocvge/order/balance/pay',
  method: 'post',
  data: params,
});
// 取消待支付订单
export const CancelOrderApi = (params) => ({
  url: '/convoy/cargocvge/order/cancel',
  method: 'post',
  data: params,
});

export const bankApi = () => ({
  url: '/account/info/xiangxin/bank',
});
